import React from 'react';
import { CardContent, CardHeader, Grid } from '@material-ui/core';
import MUICard from '@material-ui/core/Card';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useStyles } from '@/common/Card/styles';

const localizedMenuStyles = makeStyles((theme: Theme) => createStyles({
  skeletonTile: {
    borderRadius: '8px',
    marginBottom: '46px'
  }
}));

const LocalizedMenuSkeleton = (): JSX.Element => (
  <Grid>
    <CardHeader
      title={<Skeleton variant="text" height={36} width={119} />}
    />
    <CardContent>
      {
          Array.from({ length: 7 }, (_, k) => (
            <Skeleton
              key={k}
              variant="rect"
              width="70%"
              height={138}
              className={localizedMenuStyles().skeletonTile}
            />
          ))
        }
    </CardContent>

  </Grid>
);

export default LocalizedMenuSkeleton;
