import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import fontStyles from '../common/fontStyles';
import {
  smallMobileStartBreakpoint, mobileStartBreakpoint, largeStartBreakpoint
} from '../materialUi/theme';
import colors from '../common/colors';

export default makeStyles((theme) => createStyles({
  content: {
    maxWidth: '728px'
  },
  localizedMenuPageMain: {
    display: 'grid',
    gridGap: '24px',

    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      gridGap: '48px'
    }
  },
  subheading: {
    ...fontStyles.h2,
    lineHeight: '28px',
    alignSelf: 'flex-end',
    marginTop: '45px',
    marginBottom: '22px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      ...fontStyles.h2Mobile,
      margin: '16px 0',
      lineHeight: 1.17
    }
  },
  subcategoryHeading: {
    ...fontStyles.h2,
    lineHeight: '29px',
    fontSize: '24px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      fontSize: '28px',
      lineHeight: '34px'
    }
  },
  vegetarianIcon: {
    marginTop: 0,
    color: '#008900'
  },
  veggieIconContainer: {
    display: 'inline-flex'
  },
  ctaButton: {
    marginBottom: '25px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      marginTop: '44px',
      marginBottom: '24px'
    }
  },
  menu: {
    listStyle: 'none',
    padding: 0
  },
  lineupTileBottom: {
    marginTop: '-30px'
  },
  nationalMenuTile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '16px 0',
    '&:first-child': {
      borderTop: `1px solid ${colors.gray400}`
    },
    borderBottom: `1px solid ${colors.gray400}`,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      padding: '24px 0',
      '&:first-child': {
        borderTop: 'none'
      }
    }
  },
  nationalMenuTileContentWrapper: {
    display: 'flex',
    flexGrow: 1,
    background: 'transparent',
    border: 0,
    textAlign: 'left',
    cursor: 'pointer',
    padding: 0
  },
  nationalMenuTileImageWrapper: {
    position: 'relative',
    overflow: 'hidden',
    minWidth: '86px',
    width: '86px',
    height: '100px',
    borderRadius: '10px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      minWidth: '218px',
      width: '218px',
      height: '114px'
    }
  },
  nationalMenuTileImageWrapperFlex: {
    position: 'relative',
    overflow: 'hidden',
    minWidth: '86px',
    width: '86px',
    height: '100px',
    borderRadius: '10px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      minWidth: '218px',
      width: '218px',
      height: '114px'
    },
    display: 'flex',
    justifyContent: 'center'
  },
  nationalMenuTilePicture: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  nationalMenuTileImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px'
  },
  nationalMenuTileImageTransform: {
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      transform: 'translateY(-12%)'
    },
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  nationalMenuTileContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      maxWidth: '460px'
    }
  },
  nationalMenuTileProductName: {
    fontFamily: 'open_sans',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: 600,
    color: colors.gray900,
    marginBottom: '3px',
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      fontSize: '16px',
      marginBottom: '5px'
    }
  },
  nationalMenuTileProductDescription: {
    lineHeight: '21px'
  },
  nationalMenuTileCaretIcon: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    fontSize: '18px',
    flexGrow: 1,
    alignSelf: 'stretch'
  },
  tilesGrid: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridGap: '24px'
  },
  iconRoot: {
    width: '1.5em'
  },
  hiddenListItem: {
    display: 'none'
  }
}));
