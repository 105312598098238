import { Typography } from '@material-ui/core';
import React from 'react';
import constants from '../../builders/pizza/constants';
import styles from './CaloriesDisclaimer.styles';

const CaloriesDisclaimer = (): JSX.Element => {
  const classes = styles();

  return (
    <Typography
      className={classes.calorieDisclaimer}
      data-testid="calories-disclaimer"
    >
      {constants.NUTRITION_INFO_MSG}
    </Typography>
  );
};

export default CaloriesDisclaimer;
